  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 84% 4.9%;

      --card: 0 0% 100%;
      --card-foreground: 222.2 84% 4.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 222.2 84% 4.9%;

      --primary: 202.28deg 68.72% 47.65%;
      --primary-foreground: 210 40% 98%;

      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;

      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;

      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 40% 98%;

      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --ring: 222.2 84% 4.9%;

      --radius: 0.5rem;
    }

    .dark {
      --background: 222.2 84% 4.9%;
      --foreground: 210 40% 98%;

      --card: 222.2 84% 4.9%;
      --card-foreground: 210 40% 98%;

      --popover: 222.2 84% 4.9%;
      --popover-foreground: 210 40% 98%;

      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 11.2%;

      --secondary: 217.2 32.6% 17.5%;
      --secondary-foreground: 210 40% 98%;

      --muted: 217.2 32.6% 17.5%;
      --muted-foreground: 215 20.2% 65.1%;

      --accent: 217.2 32.6% 17.5%;
      --accent-foreground: 210 40% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 40% 98%;

      --border: 217.2 32.6% 17.5%;
      --input: 217.2 32.6% 17.5%;
      --ring: 212.7 26.8% 83.9%;
    }

    .elerama {
      --background: 0 0% 100%;
      --foreground: 0 0% 3.9%;

      --muted: 0 0% 96.1%;
      --muted-foreground: 0 0% 45.1%;

      --popover: 0 0% 100%;
      --popover-foreground: 0 0% 3.9%;

      --card: 0 0% 100%;
      --card-foreground: 0 0% 3.9%;

      --border: 0 0% 89.8%;

      --input: 0 0% 89.8%;

      --primary: 202.28deg 68.72% 47.65%;
      --primary-foreground: 0 85.7% 97.3%;

      --secondary: 0 0% 96.1%;
      --secondary-foreground: 0 0% 9%;

      --accent: 0 0% 96.1%;
      --accent-foreground: 0 0% 9%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;

      --ring: 0 72.2% 50.6%;

      --radius: .4rem;
    }

    .unieuro {
      --background: 0 0% 100%;
      --foreground: 240 10% 3.9%;

      --muted: 240 4.8% 95.9%;
      --muted-foreground: 240 3.8% 46.1%;

      --popover: 0 0% 100%;
      --popover-foreground: 240 10% 3.9%;

      --card: 0 0% 100%;
      --card-foreground: 240 10% 3.9%;

      --border: 240 5.9% 90%;

      --input: 240 5.9% 90%;

      --primary: 216.18deg 55.74% 23.92%;
      --primary-foreground: 355.7 100% 97.3%;

      --secondary: 31.78deg 100% 60.23%;
      --secondary-foreground: 0deg 0% 100%;

      --accent: 208.48deg 60.33% 52.09%;
      --accent-foreground: 0deg 0% 100%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;

      --ring: 142.1 76.2% 36.3%;

      --radius: ;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }

    body {
      @apply bg-background text-foreground;
    }
  }

  html,
  body,
  #__next {
    height: 100%;
    overflow: hidden;
  }

  .DropDownMenuRadioItem>span:first-child {
    display: none;
  }

  .animate-gradient {
    background: linear-gradient(-45deg, #ffffff, hsl(var(--primary)));
    background-size: 400% 400%;
    animation: animatedgradient 2s ease-in-out infinite !important;
    transition: opacity 1s linear 0s !important;
    opacity: 1;
  }

  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }


  @font-face {
    font-family: icomoonUnieuro;
    src: url('../assets/fonts/icomoonUnieuro.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  .font-icomoon {
    font-family: icomoonUnieuro !important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .icon-unieuro:before {
    font-family: icomoonUnieuro !important;
    content: "\e944";
    color: #f93;
  }

  h3[data-state="open"]+.selectedFilters {
    overflow: hidden;
    max-height: 0px;
    transition: all 0.0s ease-in-out;
  }

  h3[data-state="closed"]+.selectedFilters {
    overflow: hidden;
    max-height: 2000px;
    transition: all 0.0s ease-out 0.2s;
  }

  h3[data-state="closed"]+.selectedFilters:has(button) {
    padding: 0.5rem 0;
  }

  #recaptcha-container,
  #recaptcha-container * {
    /* width: auto !important; */
    box-shadow: none !important;
  }

  .banner {
    background-color: hsl(var(--border));
    white-space: nowrap;
    position: relative;
    line-height: 120%;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.625rem;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    text-align: center;
    margin: 0rem 0.5rem 0rem 0rem;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      right: calc(100% - (1rem + 1px));
      bottom: 0px;
      height: 100%;
      width: calc(1rem + 1px);
      background-color: white;
      -webkit-clip-path: polygon(0 0, 0% 100%, 50% 50%);
      clip-path: polygon(0% 0%, 0% 100%, 50% 50%);
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      left: calc(100%);
      bottom: 0px;
      height: 100%;
      width: calc(1rem + 1px);
      background-color: hsl(var(--border));
      -webkit-clip-path: polygon(0 0, 0% 100%, 50% 50%);
      clip-path: polygon(0% 0%, 0% 100%, 50% 50%);
    }
  }

  input.placeholder-white::placeholder {
    color: white !important;
  }

  .bg-odd-white:nth-child(odd) {
    background-color: white;
  }

  .bg-even-gray-100:nth-child(even) {
    background-color: hsl(var(--muted));
  }
